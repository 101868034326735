:root {
  --dark: #333;
  --dark-transparent: rgba(51, 51, 51, 0.75);
  --darker-transparent: rgba(32, 34, 34, 0.4);
  --light: whitesmoke;
  --light-transparent: rgba(245, 245, 245, 0.6);
  --grid-line-color: rgba(105, 105, 105, 0);

  --error: rgb(245, 101, 101);
  --focus: #63b3ed;
  --link: #90cdf4;
  --turn: rgb(60, 186, 146);

  --green: #3cba92;
  --purple: #b66df6;
  --grey: #707070;
  --gold: #ffd700;

  --win-green: var(--turn);
  --win-green-transparent: rgba(60, 186, 146, 0.75);
  --loss-red: var(--error);
  --loss-red-transparent: rgba(245, 101, 101, 0.75);
  --draw-grey: var(--grey);
}

* {
  font-family: system-ui;
  margin: 0;
  box-sizing: border-box;
}

body {
  padding: 3rem;
  background-size: 20px 20px;
  background-image: linear-gradient(
      to right,
      var(--grid-line-color) 1px,
      #333 1px
    ),
    linear-gradient(to bottom, var(--grid-line-color) 1px, #333 1px);
}

.container {
  text-align: center;
}

header {
  margin-top: clamp(1rem, 2vh, 2rem);
  text-align: center;
}

h1 {
  color: var(--light);
  font-family: "Lacquer", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(3.5rem, 5vw, 10rem);
}

h2 {
  color: var(--light);
  font-weight: 500;
  font-size: clamp(1.5rem, 2vw, 3rem);
}
h3 {
  color: var(--light);
  font-weight: 400;
  font-size: clamp(1rem, 1.8vw, 2.6rem);
}

.board {
  position: relative;
  margin: 4rem auto;
  width: clamp(150px, 50vw, 600px);
  height: clamp(150px, 50vw, 600px);
  max-height: 60vh;
  max-width: 60vh;
}

.strike {
  position: absolute;
  transform-origin: bottom !important;
  background-color: var(--win-green);
  width: 5px;
  border-radius: 1rem;
  height: clamp(110px, 45vw, 525px);
}

.transform-origin-top {
  transform-origin: top !important;
}
.transform-origin-bottom {
  transform-origin: bottom !important;
}

.board__row {
  width: 100%;
  height: 33.3%;
  display: flex;
  justify-content: center;
  color: var(--light);
}

.board__square {
  display: grid;
  place-content: center;
  font-size: clamp(3.5rem, 7vw, 9rem);
  width: 33.3%;
  /* aspect-ratio: 1; */
  text-align: center;
  /* line-height: 200px; */
}

.board__square:first-child {
  border-right: 1px solid var(--light);
}
.board__square:last-child {
  border-left: 1px solid var(--light);
}

.board__row:not(.board__row ~ .board__row) {
  border-bottom: 1px solid var(--light);
}
.board .board__row:last-of-type {
  border-top: 1px solid var(--light);
}

/* .board__row:nth-child(2) {
  border-bottom: 1px solid var(--light);
}
.board__row:last-child {
  border-top: 1px solid var(--light);
} */

form {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  padding: 2rem 0;
}

form > p {
  color: var(--light);
}

input,
.btn {
  outline: none;
  border: none;
  border-radius: 25px;
  padding: 10px;
  height: 50px;
}

.btn-center {
  margin: 0 auto;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 80%;
  position: relative;
}

.input-group label {
  color: var(--dark);
  margin-bottom: 3px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.25s ease;
  z-index: 1;
  cursor: text;
}

.input-group,
.btn {
  margin-top: 1.5rem;
}

input:focus {
  border: 2px solid var(--focus);
}

input:invalid:not(:placeholder-shown) {
  /* border: 2px solid var(--error); */
}

input:focus ~ label,
input:valid ~ label {
  top: -12px;
  color: var(--focus);
  font-size: smaller;
  font-weight: bolder;
}

.btn {
  display: block;
  width: 300px;
  font-weight: 700;
  color: var(--light);
}

.btn,
a {
  font-size: 1rem;
  cursor: pointer;
}

.btn:hover {
  transform: scale(1.05);
}

.btn-primary {
  background: radial-gradient(
    circle at 10% 20%,
    rgb(111, 111, 219) 0%,
    rgb(182, 109, 246) 72.4%
  );
}
.btn-secondary {
  background: radial-gradient(
    circle at 10% 20%,
    rgb(58, 62, 88) 3.6%,
    rgb(119, 127, 148) 120.8%
  );
}
.btn-icon {
  width: 60px !important;
  height: 100%;
  aspect-ratio: 1;
  margin: 0;
}

.btn-danger {
  background: radial-gradient(
    371px at 2.9% 14.3%,
    rgb(255, 0, 102) 0%,
    rgb(80, 5, 35) 100.7%
  );
}
.btn-disabled {
  cursor: default;
  background: grey;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

p.validation-error {
  width: 75%;
  text-align: end;
  margin-top: 2px;
  color: var(--error);
}

p.form-message {
  margin-top: 1rem;
}
p.form-message-success {
  /* font-weight: bolder; */
  color: var(--green);
}
a {
  text-decoration: none;
  font-weight: bolder;
  color: var(--link);
}

a:hover {
  text-decoration: underline;
}

a.btn {
  display: grid;
  place-content: center;
}

a.btn:hover {
  text-decoration: none;
}

#spectate-button {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 992px) {
  form {
    border-right: 2px solid var(--light);
    margin: 0;
  }

  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #spectate-button {
    margin: 0;
    margin-left: 2rem;
  }

  #previous-games-container {
    width: 60%;
  }
}

section#playButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 70px;
  left: 0;
  right: 0;
  background: var(--dark-transparent);
  border-top: 2px solid var(--grey);
}

.nav {
  width: 100%;
  height: 100%;
  background: transparent;
}

ul {
  height: 100%;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-button {
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
}

.nav-button {
  cursor: pointer;
  height: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  width: 33.3%;
  color: var(--light);
  font-size: 1.7rem;
}

.nav-button p {
  font-size: 1rem;
}

.nav-button > a {
  display: block;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-turn-message {
  font-size: 2rem;
  color: whitesmoke;
  font-weight: 500;
}

.game-result-message {
  font-weight: 700;
}

.game-result-message-win {
  color: var(--win-green);
}
.game-result-message-loss {
  color: var(--loss-red);
}
.game-result-message-draw {
  color: var(--draw-grey);
}

.player-card-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.player-card {
  transition: background-color 0.5s;
  background: var(--dark-transparent);
  border: 2px solid var(--light);
  color: var(--light);
  font-weight: bolder;
  font-size: 1.2rem;
  padding: 1rem;
  width: 130px;
  overflow: hidden;
}

.player-card-turn {
  background: var(--turn);
}

.player-card span {
  font-size: smaller;
  font-weight: lighter;
}

.loading-ellipses:after {
  content: "";
  display: inline-block;
  animation: ellipsis steps(1, end) 2s infinite;
}

.message {
  font-size: 1.6rem;
  color: whitesmoke;
  font-weight: 500;
  margin: 5px 0;
}

.message-smaller {
  font-size: smaller;
}

.message-error {
  color: var(--error);
}

.message-smaller {
  font-size: 1.2rem;
}

.message-link {
  font-size: 1rem;
  overflow-x: scroll;
}

.message-link::-webkit-scrollbar {
  width: 10px;
}

.message-link::-webkit-scrollbar-track {
  background: var(--light);
  border-radius: 10px;
}
.message-link::-webkit-scrollbar-thumb {
  /* width: 5px; */
  border-radius: 10px;
  background: var(--dark-transparent);
}

.message-link::-webkit-scrollbar-thumb:hover {
  background: var(--dark);
}

.game-invite-link > span {
  margin: 5px;
  margin-left: 10px;
}

.game-invite-link {
  width: clamp(200px, 80vw, 420px);
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border: 1px solid var(--purple);
  border-radius: 10px;
}

.purple-text {
  color: var(--purple);
}
.green-text {
  color: var(--green);
}
.red-text {
  color: var(--loss-red);
}
.grey-text {
  color: var(--grey);
}

@keyframes ellipsis {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.ongoing-games {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2vh;
}

.ongoing-game-card {
  display: flex;
  justify-content: center;
  gap: 2vw;
}

.ongoing-games-player-card {
  padding: 0 1rem;
  border-radius: 20px;
  background: var(--light-transparent);
  display: grid;
  place-content: center;
}

.ongoing-games-player-card > p {
  font-size: 1rem;
}

.ongoing-games-player-card > span {
  font-size: 1.3rem;
  font-weight: 600;
}

section#profile {
  display: flex;
  flex-direction: column;
}

#previous-games-container {
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid var(--light);
  border-radius: 10px;
  /* width: 60%;  */
}

.previous-games {
  margin: 1rem 0;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  align-items: center;
}

.previous-games article {
  max-width: 80%;
  display: flex;
  gap: 3vw;
  justify-content: space-around;
  align-items: center;
}

.previous-games article > p {
  font-size: 1.2rem;
  color: var(--light);
  display: flex;
  flex-direction: column;
}

.previous-games article span {
  font-weight: bolder;
}

.previous-game-player-info {
  padding: 1rem;
  border-radius: 15px;
  border: 1px solid var(--light);
  color: var(--light);
  min-width: 115px;
}

.win {
  background-color: var(--win-green-transparent);
}
.loss {
  background-color: var(--loss-red-transparent);
}

.draw {
  background-color: var(--dark);
}

.loss-text {
  color: var(--loss-red);
}
.win-text {
  color: var(--win-green-transparent);
}
.draw-text {
  color: var(--dark);
}

.text-red {
  color: var(--error);
}

.leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboard-table {
  color: var(--light);
  width: 60%;
  max-width: 500px;
  margin-top: 2vh;
  border-radius: 1rem;
  font-size: 1rem;
  background: var(--dark-transparent);
  border: 1px solid var(--light-transparent);
}

.leaderboard-table thead,
.leaderboard-table tbody {
  padding: 1rem;
}

.leaderboard-table tr {
  padding: 10px 5px;
}

.leaderboard-table th:not(:last-of-type),
.leaderboard-table td:not(:last-of-type) {
  border-right: 1px solid var(--light-transparent);
}

.leaderboard-table td {
  padding: 5px;
}

.leaderboard-table tr:nth-child(2n-1) {
  background-color: var(--darker-transparent);
}

.leaderboard-table a {
  font-weight: normal;
}

.leaderboard-table td.position {
  color: var(--purple);
}

span#crown {
  color: var(--gold);
}
